"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rechnungenColumnDefsExtended = exports.rechnungenColumnDefs = void 0;
const behandlerService_1 = require("@/services/behandlerService");
const rechnungenStore_1 = require("@/state/rechnungenStore");
const usersStore_1 = require("@/state/usersStore");
const common_ui_1 = require("@rose/common-ui");
const base_1 = require("../../../../base");
const types_1 = require("@rose/types");
const lodash_1 = require("lodash");
const behandlerService = new behandlerService_1.BehandlerService();
exports.rechnungenColumnDefs = [
    {
        headerName: 'Patient',
        sortable: true,
        pinned: 'left',
        sort: 'asc',
        width: 300,
        comparator: common_ui_1.patientComparer,
        valueFormatter: params => params && (0, common_ui_1.patientDisplayText)(params.data), // used by export
        cellRendererSelector: (params) => {
            if (params.data.isSummary) {
                return { component: 'RechnungenSummary' };
            }
            return { component: 'PatientCell' };
        },
        applyFilterInSummaryRow: true,
        suppressMovable: true,
        noexport: true,
    },
    {
        headerName: 'Termin',
        field: 'folgetermine',
        cellClass: 'ag-cell-with-component',
        width: 30,
        sortable: true,
        resizable: true,
        comparator: common_ui_1.folgeTermineComparer,
        valueFormatter: common_ui_1.folgeTermineFormatter,
        cellRendererSelector: (params) => {
            if (params.data.isSummary) {
                return undefined;
            }
            return { component: 'TermineCell' };
        },
        exportWidth: 100,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.folgeTermineRawDateFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Rechnungsnummer',
        field: 'rgnr',
        width: 110,
        resizable: true,
        sortable: true,
    },
    {
        headerName: 'Datum',
        field: 'tag',
        sortable: true,
        resizable: true,
        valueFormatter: common_ui_1.dateLongYearFormatter,
        width: 90,
        suppressMovable: true,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Honorar',
        field: 'honorar',
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: common_ui_1.classZeroValue,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Labor',
        field: 'labor',
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: common_ui_1.classZeroValue,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Material',
        cellClass: common_ui_1.classZeroValue,
        field: 'material',
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Gesamt',
        field: 'gesamt',
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        cellClass: (params) => {
            let hkpGesamt = params.data.hkpGesamt;
            if (hkpGesamt < params.data.gesamt) {
                return 'cell-warning';
            }
        },
        tooltipValueGetter: (params) => {
            let hkpGesamt = params.data.hkpGesamt;
            if (hkpGesamt < params.data.gesamt) {
                return 'Der Rechnungsbetrag ist höher als der HKP-Gesamtbetrag';
            }
        },
        exportUnit: types_1.Units.EURO,
    },
    {
        // rot wenn > als gesamt
        headerName: 'HKP',
        field: 'hkpGesamt',
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellStyle: { 'text-align': 'right' },
        cellClass: common_ui_1.classZeroValue,
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Erstattung',
        field: 'erstattung',
        width: 85,
        cellClass: common_ui_1.classZeroValue,
        valueFormatter: params => (0, common_ui_1.euroFormatter)(Object.assign(Object.assign({}, params), { value: -params.value })),
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Rest',
        field: 'rest',
        cellClass: common_ui_1.classZeroValue,
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Erlassen',
        field: 'erlassen',
        cellClass: common_ui_1.classZeroValue,
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'RZ',
        field: 'rechenzentrum',
        width: 60,
        valueFormatter: common_ui_1.booleanJaNeinFormatter,
        resizable: true,
        sortable: true,
        suppressMovable: true,
    },
    {
        headerName: 'Fällig',
        field: 'faellig',
        sortable: true,
        resizable: true,
        valueFormatter: common_ui_1.dateLongYearFormatter,
        width: 90,
        suppressMovable: true,
        cellClass: params => {
            var _a, _b;
            if (((_a = params.data) === null || _a === void 0 ? void 0 : _a.bezahlt) || !((_b = params.data) === null || _b === void 0 ? void 0 : _b.faellig)) {
                return;
            }
            let ueberFaellig = (0, base_1.roseDayjs)(params.data.faellig).isBefore((0, base_1.roseDayjs)());
            if (ueberFaellig) {
                return 'cell-warning';
            }
        },
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Bezahltdatum',
        field: 'bezahltdatum',
        sortable: true,
        resizable: true,
        valueFormatter: common_ui_1.dateLongYearFormatter,
        width: 80,
        suppressMovable: true,
        cellClass: params => {
            var _a;
            if (!((_a = params.data) === null || _a === void 0 ? void 0 : _a.faellig)) {
                return;
            }
            let ueberFaellig = params.data.bezahltdatum &&
                params.data.faellig &&
                (0, base_1.roseDayjs)(params.data.bezahltdatum).isAfter(params.data.faellig);
            if (ueberFaellig) {
                return 'cell-warning';
            }
        },
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Stammbehandler',
        field: 'behandler',
        valueFormatter: (0, common_ui_1.behandlerFormatter)(behandlerService),
        width: 130,
        sortable: true,
        resizable: true,
        suppressMovable: true,
    },
    {
        headerName: 'Leistungserbringer',
        field: 'honorardetails.behandlerverteilung',
        cellRenderer: 'LeistungserbringerCell',
        cellRendererParams: { typ: 'rechnungen' },
        valueFormatter: (0, common_ui_1.behandlerArrayFormatter)(behandlerService, v => v.behandler),
        width: 150,
        resizable: true,
        suppressMovable: true,
        exportWidth: 250,
    },
    {
        headerName: 'Mahnstufe',
        field: 'mahnstufe',
        width: 90,
        resizable: true,
        sortable: true,
        suppressMovable: true,
        cellRenderer: 'MahnstufeInfoCell',
    },
    {
        headerName: 'Mahnstopp',
        field: 'mahnstoppDatum',
        editable: true,
        cellEditor: 'DateRangeCell',
        valueFormatter: common_ui_1.dateFormatter,
        onCellValueChanged: v => rechnungenStore_1.rechnungenStore.dispatch.saveRechnungMahninfo(v),
        width: 125,
        resizable: true,
        sortable: true,
        suppressMovable: true,
        cellClass: params => {
            var _a;
            let mahnstoppDatum = (_a = params.data) === null || _a === void 0 ? void 0 : _a.mahnstoppDatum;
            if (mahnstoppDatum && (0, base_1.roseDayjs)(mahnstoppDatum).isBefore((0, base_1.roseDayjs)())) {
                return 'cell-warning';
            }
        },
    },
    {
        headerName: 'Mahnstopp Kommentar',
        field: 'mahnstoppKommentar',
        editable: true,
        onCellValueChanged: (v) => rechnungenStore_1.rechnungenStore.dispatch.saveRechnungMahninfo(v),
        resizable: true,
        sortable: true,
        suppressMovable: true,
        width: 200,
    },
    {
        headerName: 'Letzte Bearbeitung',
        field: 'mahnstoppErsteller',
        // @ts-ignore
        valueFormatter: (params) => (0, usersStore_1.resolveUserName)(params.value),
        resizable: true,
        sortable: true,
        suppressMovable: true,
        width: 200,
    },
    {
        headerName: 'Summe gefilterter Leistungen',
        field: 'filteredLeistungen',
        // @ts-ignore
        valueFormatter: (params) => { var _a; return (0, common_ui_1.euroFormatter)({ value: (0, lodash_1.sumBy)((_a = params.data) === null || _a === void 0 ? void 0 : _a.filteredLeistungen, l => l.betrag) }); },
        resizable: true,
        sortable: true,
        suppressMovable: true,
        width: 200,
    },
];
exports.rechnungenColumnDefsExtended = [
    {
        headerName: 'Von (Leistungszeitraum)',
        field: 'von',
        width: 100,
        valueFormatter: common_ui_1.dateLongYearFormatter,
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Bis (Leistungszeitraum)',
        field: 'bis',
        width: 100,
        valueFormatter: common_ui_1.dateLongYearFormatter,
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.DATUM,
        excelValueFormatter: common_ui_1.dateRawFormatter,
        useFormatterForExport: true,
    },
    {
        headerName: 'Ertrag',
        field: 'ertrag',
        width: 85,
        valueFormatter: common_ui_1.euroFormatter,
        cellClass: common_ui_1.classZeroValue,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.EURO,
    },
    {
        headerName: 'Ertrag %',
        field: 'ertragprozent',
        width: 85,
        valueFormatter: common_ui_1.percent100Formatter,
        cellClass: common_ui_1.classZeroValue,
        cellStyle: { 'text-align': 'right' },
        sortable: true,
        resizable: true,
        suppressMovable: true,
        exportUnit: types_1.Units.PROZENTEROUNDED,
    },
];
